import React, { useEffect, useMemo, useState } from "react";
import "./main.css";
import Question from "./Question";
import { Link } from "react-router-dom";
import { questions } from "./questions";
import axios from "axios";
import logo from "./logo.png";

const Main = () => {
  const [feedbackResults, setFeedbackResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const getFeedbackResults = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "https://feedbackform.greenenco.in/api/v1/getFeedbackResult"
      );
      setFeedbackResults(data?.result);
    } catch (error) {
      setError("Could not fetch data");
    } finally {
      setLoading(false);
    }
  };

  let result = useMemo(() => {
    let obj = {};
    let data = feedbackResults || [];
    data?.forEach((e, index) => {
      for (let i in e) {
        if (i !== "fullName") {
          if (obj[i]) {
            if (obj[i][e[i]]) obj[i][e[i]] = obj[i][e[i]] + 1;
            else obj[i][e[i]] = 1;
          } else
            obj[i] = {
              [e[i]]: 1,
            };
        }
      }
    });
    obj["len"] = data?.length || 0;
    return obj || {};
  }, [feedbackResults]);
  useEffect(() => {
    getFeedbackResults();
  }, []);

  return (
    <>
      <ul className="colorlib-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="error-container">
        <p></p>
      </div>
      <div className="nav-bar">
        <div className="logo">
          <img src={logo} alt="GreenEnco" />
        </div>
      </div>
      {!loading ? (
        <>
          {error ? (
            <div
              style={{
                height: "85vh",
                width: "100%",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>{error}</p>
            </div>
          ) : (
            <div className="quiz-container">
              <p
                style={{
                  position: "absolute",
                  top: "-2rem",
                  right: "1rem",
                  color: "#fff",
                }}
              >
                Total no of participant: {feedbackResults?.length || 0}
              </p>
              <h3>Audience Mindfulness Questionnaires</h3>
              <form className="options-list">
                {questions?.map((e, index) => {
                  return (
                    <Question
                      data={e}
                      key={index}
                      element={{
                        data: result[`question${index + 1}`] || {},
                        len: result.len || 0,
                      }}
                      qn={index + 1}
                    />
                  );
                })}
              </form>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: "85vh",
            width: "100%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Fetching data . . .</p>
        </div>
      )}
      <div className="footer">
        <p>
          ©{" "}<Link target="_blank" to="https://greenenco.co.uk">
            greenenco.co.uk.
          </Link> All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Main;
