import React from "react";

const Question11 = ({ qn, data, element }) => {
  return (
    <div className="question-container">
      <p>
        {data.qn}. {data.question}
      </p>
      <div className="options">
        <label
          style={{
            background: `linear-gradient(to right, rgb(143, 235, 175) ${formatNumber(
              element["data"][1],
              element.len
            )}% ,
               white ${formatNumber(element["data"][1], element.len)}%)`,
          }}
          className={`option `}
        >
          <span>{formatNumber(element["data"][1], element.len)}%</span>
          {data.options[0]}
        </label>
        <label
          style={{
            background: `linear-gradient(to right, rgb(143, 235, 175) ${formatNumber(
              element["data"][2],
              element.len
            )}% ,
               white ${formatNumber(element["data"][2], element.len)}%)`,
          }}
          className={`option `}
        >
          <span>{formatNumber(element["data"][2], element.len)}%</span>
          {data.options[1]}
        </label>
        <label
          style={{
            background: `linear-gradient(to right, rgb(143, 235, 175) ${formatNumber(
              element["data"][3],
              element.len
            )}% ,
               white ${formatNumber(element["data"][3], element.len)}%)`,
          }}
          className={`option`}
        >
          <span>{formatNumber(element["data"][3], element.len)}%</span>
          {data.options[2]}
        </label>
        <label
          style={{
            background: `linear-gradient(to right, rgb(143, 235, 175) ${formatNumber(
              element["data"][4],
              element.len
            )}% ,
               white ${formatNumber(element["data"][4], element.len)}%)`,
          }}
          className={`option`}
        >
          <span>{formatNumber(element["data"][4], element.len)}%</span>
          {data.options[3]}
        </label>
      </div>
    </div>
  );
};

export default Question11;

const formatNumber = (cnt, total) => {
  let val = (cnt * 100) / total;
  if (isNaN(val)) val = 0;
  return Number.isInteger(val) ? val.toFixed(0) : val.toFixed(2);
};
